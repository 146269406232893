import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { DEVICES_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const DevicesViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${DEVICES_PREFIX_PATH}/painel-senhas`} component={lazy(() => import(`./painel-senhas`))} />
        <Route path={`${DEVICES_PREFIX_PATH}/tablet-senhas`} component={lazy(() => import(`./tablet-senhas`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/app`} />
      </Switch>
    </Suspense>
  )
}

export default DevicesViews;

