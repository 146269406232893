import fetch from 'auth/FetchInterceptor'

const dropDownService = {}

dropDownService.getAll = async function () {
    return fetch({
        url: '/v1/rest/drop-down-unidades/',
        method: 'get',
        headers: {is_app: true}
    })
}

dropDownService.put = async function (id) {
    return fetch({
        url: '/v1/rest/drop-down-unidades/'+ id,
        method: 'put',
    })
}

export default dropDownService;