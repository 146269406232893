import React, {createContext, useState, useRef, useEffect} from 'react';
import Peer from 'simple-peer';
import {VideoSocket} from "services/SocketService";

const SocketContext = createContext();
const socket = VideoSocket

const ContextProvider = ({children}) => {
    const [stream, setStream] = useState(null);
    const [me, setMe] = useState('');
    const [call, setCall] = useState({});
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [name, setName] = useState('');
    const [erro, setErro] = useState(false);
    const [imuted, setImuted] = useState(false);
    const [youmuted, setYoumuted] = useState(false);

    const myVideo = useRef({
        srcObject: null,
    });
    const userVideo = useRef({
        srcObject: null,
    });
    const connectionRef = useRef();

    // Pede permissão para usar a câmera e o microfone
    const getUserData = async () => {
        try {
            const currentStream = await navigator.mediaDevices.getUserMedia({video: true, audio: true})
            setStream(currentStream);
            myVideo.current.srcObject = currentStream;
            setName(JSON.parse(localStorage.getItem('user')).nome)
            if (erro) {
                setErro(false)
            }
        } catch (error) {
            setErro(true)
        }
    }

    useEffect(() => {
        socket.on('me', (id) => setMe(id));
        socket.on('calluser', ({from, name: callerName, signal}) => {
            setCall({isReceivedCall: true, from, name: callerName, signal});
        });
        socket.on('mute', () => {
            setYoumuted(true)
        });
        socket.on('unmute', () => {
            setYoumuted(false)
        });
    }, []);

    const answerCall = () => {
        setCallAccepted(true);

        const peer = new Peer({initiator: false, trickle: false, stream});

        peer.on('signal', (data) => {
            socket.emit('answercall', {
                signal: data,
                to: call.from,
                name: JSON.parse(localStorage.getItem('user')).nome
            });
        });

        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream;
        });

        socket.on('callended', () => {
            setCallEnded(true);
            peer.destroy()
            window.location.reload();
        });

        peer.signal(call.signal);

        connectionRef.current = peer;
    }

    const callUser = (id) => {
        const peer = new Peer({initiator: true, trickle: false, stream});

        peer.on('signal', (data) => {
            socket.emit('calluser', {userToCall: id, signalData: data, from: me, name});
        });

        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream;
        });

        socket.on('callaccepted', (data) => {
            setCallAccepted(true);
            setCall({
                ...call,
                from: id,
                name: data.nome
            })
            peer.signal(data.signal);
        });

        connectionRef.current = peer;
    }

    const mute = () => {
        setImuted(true)
        socket.emit('mute', {to: call.from});
    }

    const unmute = () => {
        setImuted(false)
        socket.emit('unmute', {to: call.from});
    }

    const leaveCall = () => {
        socket.emit('callended', {to: call.from});
        // connectionRef.current.destroy();
        // userVideo.current.srcObject = null;
        // setCallAccepted(false);
        // setCallEnded(false);
        // setCall({})
        window.location.reload();
    }

    return (
        <SocketContext.Provider value={{
            call,
            callAccepted,
            myVideo,
            userVideo,
            stream,
            name,
            setName,
            callEnded,
            me,
            callUser,
            leaveCall,
            answerCall,
            getUserData,
            setStream,
            erro,
            imuted,
            setImuted,
            youmuted,
            setYoumuted,
            mute,
            unmute
        }}>
            {children}
        </SocketContext.Provider>
    );
}

export {ContextProvider, SocketContext};
