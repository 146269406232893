import React, {lazy, Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import {CAIXA_PREFIX_PATH} from 'configs/AppConfig';
import { connect } from 'react-redux';

export const TreinamentoViews = props => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route path={`${CAIXA_PREFIX_PATH}/faturas`} component={lazy(() => import(`./faturas`))} />
				<Route path={`${CAIXA_PREFIX_PATH}/escolher-plano`} component={lazy(() => import(`./escolher-plano`))} />
				<Route path={`${CAIXA_PREFIX_PATH}/dados-cobranca`} component={lazy(() => import(`./dados-cobranca`))} />
				{props.dadosCobranca 
					? <Redirect from={`${CAIXA_PREFIX_PATH}`} to={`${CAIXA_PREFIX_PATH}/faturas`} />
					: <Redirect from={`${CAIXA_PREFIX_PATH}`} to={`${CAIXA_PREFIX_PATH}/dados-cobranca`} />
				}
			</Switch>
		</Suspense>
	);
};

const mapStateToProps = ({gui}) => {
	const {dadosCobranca} = gui;
	return {dadosCobranca};
};

export default connect(mapStateToProps)(TreinamentoViews);
