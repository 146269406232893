import { Button } from 'antd';
import { APP_PREFIX_PATH, CAIXA_PREFIX_PATH, PUBLIC_PREFIX_PATH } from 'configs/AppConfig';
import React from 'react'
import { ImportOutlined, SwapOutlined, DollarOutlined, AuditOutlined } from '@ant-design/icons';
import { Switch, Route, useHistory, } from "react-router-dom";
import {signOut} from 'redux/actions/Auth';
import CaixaViews from 'views/caixa-views'
import { connect } from 'react-redux';
import useTemPermissao from 'hooks/useTemPermissao';
import Permissoes from 'enum/permissao-enum';

export const CaixaLayout = (props) => {
	const {
		signOut,
		paymentRequired,
		dadosCobranca
	} = props;

	const history = useHistory();

	const temPermissaoEditarPlano = useTemPermissao(Permissoes.PLANOS_DO_SISTEMA.ESCOLHER_PLANO);
	const temPermissaoDadosCobranca = useTemPermissao(Permissoes.PLANOS_DO_SISTEMA.ALTERAR_DADOS_COBRANCA);

	const getLogo = () => {
		return `${PUBLIC_PREFIX_PATH}/cliniProLogo.png`
	}

	return (
		<div className="device-container">
			<header
				style={{
					backgroundColor: '#004C4D',
					height: '50px',
					width: '100%',
				}}
			>
				<Button 
					type="primary" 
					onClick={() => {
						if(paymentRequired) signOut()
						else history.push(`${APP_PREFIX_PATH}`)
					}}
					style={{ float: 'left', margin: "5px", marginLeft: "10px"}} 
					icon={<ImportOutlined />} 
				/> 
				{(window.location.hash !== "#/caixa/faturas" && ((paymentRequired && dadosCobranca) || !paymentRequired)) &&
					<Button
						type="primary" 
						onClick={() => {
							history.push(`${CAIXA_PREFIX_PATH}/faturas`)
						}}
						style={{ float: 'left', margin: "5px"}} 
						icon={<DollarOutlined />} 
					/>
				}
				{(temPermissaoEditarPlano && window.location.hash !== "#/caixa/escolher-plano") &&
					<Button 
						type="primary" 
						onClick={() => {
							history.push(`${CAIXA_PREFIX_PATH}/escolher-plano`)
						}}
						style={{ float: 'left', margin: "5px"}} 
						icon={<SwapOutlined />} 
					/> 
				}
				{(temPermissaoDadosCobranca && window.location.hash !== "#/caixa/dados-cobranca") &&
					<Button 
						type="primary" 
						onClick={() => {
							history.push(`${CAIXA_PREFIX_PATH}/dados-cobranca`)
						}}
						style={{ float: 'left', margin: "5px"}} 
						icon={<AuditOutlined />} 
					/>
				}
				<div style={{
					margin: "auto", 
					width: "min-content",
					paddingRight: "20px",
				}}>
                    <img
						src={getLogo()}
						alt={`logo`}
						style={{
							marginTop: "5px",
							height: "40px",
						}}
					/>
				</div>
			</header>
				<Switch>
					<Route path="" component={CaixaViews} />
				</Switch>
			<footer
				style={{
					position: 'fixed',
					backgroundColor: '#004C4D',
					height: '50px',
					width: '100%',
					bottom: 0,
				}}
			></footer>
		</div>
	)
}

const mapStateToProps = ({gui}) => {
	const {paymentRequired, dadosCobranca} = gui;
	return {paymentRequired, dadosCobranca};
};

export default connect(mapStateToProps, {signOut})(CaixaLayout);
