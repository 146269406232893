import {
  SET_IS_LOADING,
  SET_TOKEN_EXPIRED,
  SET_PAYMENT_REQUIRED,
  SET_PAYMENT_REQUIRED_LOGIN
} from '../constants/Gui';

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    isLoading
  }
};

export const setTokenExpired = (isExpired) => {
  return {
    type: SET_TOKEN_EXPIRED,
    isExpired
  }
};

export const setPaymentRequired = (paymentRequired, freeTrial, dadosCobranca) => {
  return {
    type: SET_PAYMENT_REQUIRED,
    paymentRequired,
    dadosCobranca,
    freeTrial,
  }
}

export const setPaymentRequiredLogin = (paymentRequired, freeTrial, dadosCobranca) => {
  return {
    type: SET_PAYMENT_REQUIRED_LOGIN,
    paymentRequired,
    dadosCobranca,
    freeTrial,
  }
}