import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useMemo, useState } from 'react'

// controlador de modal, controla se ele vai estar visivel ou não, é passado o botão para dentro tambem

const ModalController = (props) => {
    const {
        renderForm,
        width,
        renderButton,
        title,
        style,
        onClose,
        forceRender,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [firstCall, setFirstCall] = useState(true);

    const onClick = () => {
        setIsModalVisible(true);
    }

    const closeModal = () => {
        setIsModalVisible(false);
    }

    const form = useMemo(() => {
        return renderForm({ closeModal, isModalVisible })
    }, [renderForm, isModalVisible])

    useEffect(() => {
        if (!firstCall) {
            if (!isModalVisible && onClose) {
                onClose();
            } 
        } else {
            setFirstCall(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible])

    return (
        <div>
            { renderButton({ onClick }) }
            <Modal
                forceRender={forceRender}
                visible={isModalVisible}
                footer={null}
                onCancel={closeModal}
                destroyOnClose={true}
                maskClosable={false}
                width={width}
                title={title}
                style={style}
            >
                { form }
            </Modal>
        </div>
    );
};

export default ModalController
