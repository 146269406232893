import React, {lazy, Suspense, useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig';
import {notification} from 'antd';
import {socket} from 'services/SocketService';
import {connect} from 'react-redux';

export const AppViews = ({user}) => {
	function onNovaMensagem(value) {
		if (!window.location.hash.includes('/app/chat')) {
			notification.config({
				maxCount: 1,
			});
			notification.info({
				message: `Você recebeu uma nova mensagem.`,
			});
		}
	}

	function login() {
		socket.emit('login', localStorage.getItem('auth_token'));
	}

	if (user.idusuario && socket.connected) {
		login();
	}

	useEffect(() => {
		socket.on('nova mensagem', onNovaMensagem);

		return () => {
			socket.off('nova mensagem', onNovaMensagem);
		};

		// eslint-disable-next-line
	}, [socket.connected]);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route path={`${APP_PREFIX_PATH}/agenda/:idprofissional`} component={lazy(() => import(`./agenda`))} />
				<Route path={`${APP_PREFIX_PATH}/agenda`} component={lazy(() => import(`./agenda`))} />
				<Route
					path={`${APP_PREFIX_PATH}/relacao-de-orcamentos`}
					component={lazy(() => import(`./relacao-de-orcamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agendamento/:id/:data/:id_cliente/:id_tratamento`}
					component={lazy(() => import(`./agendamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agendamento/:id/:data`}
					component={lazy(() => import(`./agendamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/documentos`}
					component={lazy(() => import(`./documentos`))}
				/>
				<Route path={`${APP_PREFIX_PATH}/agendamento/:id`} component={lazy(() => import(`./agendamento`))} />
				<Route path={`${APP_PREFIX_PATH}/agendamento`} component={lazy(() => import(`./agendamento`))} />
				<Route
					path={`${APP_PREFIX_PATH}/lista-pacientes`}
					component={lazy(() => import(`./lista-pacientes`))}
				/>
				<Route path={`${APP_PREFIX_PATH}/cliente/:id/historico-anamnese`} component={lazy(() => import(`./cliente/historico-anamnese`))} />
				<Route path={`${APP_PREFIX_PATH}/cliente/:id`} component={lazy(() => import(`./cliente`))} />
				<Route path={`${APP_PREFIX_PATH}/paciente/:id`} component={lazy(() => import(`./paciente`))} />
				<Route path={`${APP_PREFIX_PATH}/paciente`} component={lazy(() => import(`./paciente`))} />
				<Route path={`${APP_PREFIX_PATH}/lista-estoque`} component={lazy(() => import(`./lista-estoque`))} />
				<Route path={`${APP_PREFIX_PATH}/estoque/:id`} component={lazy(() => import(`./estoque`))} />
				<Route path={`${APP_PREFIX_PATH}/estoque`} component={lazy(() => import(`./estoque`))} />

				{/*Fluxo de financeiro*/}
				<Route
					path={`${APP_PREFIX_PATH}/financeiro/fluxo-de-caixa`}
					component={lazy(() => import(`./financeiro/fluxo-de-caixa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro/comissoes`}
					component={lazy(() => import(`./financeiro/comissoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro/metas`}
					component={lazy(() => import(`./financeiro/metas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro/inadimplentes`}
					component={lazy(() => import(`./financeiro/inadimplentes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro/transferencia`}
					component={lazy(() => import(`./financeiro/transferencia`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/relatorios/media-fechamento`}
					component={lazy(() => import(`./relatorios/media-fechamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/ticket-medio-receitas`}
					component={lazy(() => import(`./relatorios/ticket-medio-receitas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/ticket-medio-usuarios`}
					component={lazy(() => import(`./relatorios/ticket-medio-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/relatorio-orcamentos`}
					component={lazy(() => import(`./relatorios/relatorio-orcamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/resumo`}
					component={lazy(() => import(`./relatorios/resumo`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/retirar-produto-estoque/:id/:produto/:quantidade`}
					component={lazy(() => import(`./retirar-produto-estoque`))}
				/>

				{/*Fluxo de Cadastros Secundários*/}
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-planos`}
					component={lazy(() => import(`./cadastros-secundarios/lista-planos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/plano/:id`}
					component={lazy(() => import(`./cadastros-secundarios/plano`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/plano`}
					component={lazy(() => import(`./cadastros-secundarios/plano`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-clientes-planos`}
					component={lazy(() => import(`./cadastros-secundarios/lista-clientes-planos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-clientes-por-plano/:id`}
					component={lazy(() => import(`./cadastros-secundarios/lista-clientes-por-plano`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-especialidades`}
					component={lazy(() => import(`./cadastros-secundarios/lista-especialidades`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/especialidade/:id`}
					component={lazy(() => import(`./cadastros-secundarios/especialidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/especialidade`}
					component={lazy(() => import(`./cadastros-secundarios/especialidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-usuarios`}
					component={lazy(() => import(`./cadastros-secundarios/lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/usuario/:id`}
					component={lazy(() => import(`./cadastros-secundarios/usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/usuario`}
					component={lazy(() => import(`./cadastros-secundarios/usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-profissionais`}
					component={lazy(() => import(`./cadastros-secundarios/lista-profissionais`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/profissional/:id`}
					component={lazy(() => import(`./cadastros-secundarios/profissional`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/profissional`}
					component={lazy(() => import(`./cadastros-secundarios/profissional`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-unidades`}
					component={lazy(() => import(`./cadastros-secundarios/lista-unidades`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/unidade/:id`}
					component={lazy(() => import(`./cadastros-secundarios/unidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/unidade`}
					component={lazy(() => import(`./cadastros-secundarios/unidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-empresas`}
					component={lazy(() => import(`./cadastros-secundarios/lista-empresas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/empresa/:id`}
					component={lazy(() => import(`./cadastros-secundarios/empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/empresa`}
					component={lazy(() => import(`./cadastros-secundarios/empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-anamneses`}
					component={lazy(() => import(`./cadastros-secundarios/lista-anamneses`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/anamnese/:id`}
					component={lazy(() => import(`./cadastros-secundarios/anamnese`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/anamnese-ordem`}
					component={lazy(() => import(`./cadastros-secundarios/anamnese-ordem`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/anamnese`}
					component={lazy(() => import(`./cadastros-secundarios/anamnese`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-indicacoes`}
					component={lazy(() => import(`./cadastros-secundarios/lista-indicacoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/indicacao/:id`}
					component={lazy(() => import(`./cadastros-secundarios/indicacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/indicacao`}
					component={lazy(() => import(`./cadastros-secundarios/indicacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-registros`}
					component={lazy(() => import(`./cadastros-secundarios/lista-registros`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-tratamentos/:id`}
					component={lazy(() => import(`./cadastros-secundarios/lista-tratamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/tratamento/:idplano/:idtratamento`}
					component={lazy(() => import(`./cadastros-secundarios/tratamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/tratamento/:idplano`}
					component={lazy(() => import(`./cadastros-secundarios/tratamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-fornecedores`}
					component={lazy(() => import(`./cadastros-secundarios/lista-fornecedores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/fornecedor/:id`}
					component={lazy(() => import(`./cadastros-secundarios/fornecedor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/fornecedor`}
					component={lazy(() => import(`./cadastros-secundarios/fornecedor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-rotulos`}
					component={lazy(() => import(`./cadastros-secundarios/lista-rotulos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/rotulo/:id`}
					component={lazy(() => import(`./cadastros-secundarios/rotulo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/rotulo`}
					component={lazy(() => import(`./cadastros-secundarios/rotulo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-questionarios`}
					component={lazy(() => import(`./cadastros-secundarios/lista-questionarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/questionario/:id`}
					component={lazy(() => import(`./cadastros-secundarios/questionario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/questionario`}
					component={lazy(() => import(`./cadastros-secundarios/questionario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/contratos/:id`}
					component={lazy(() => import(`./cadastros-secundarios/contratos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/contratos`}
					component={lazy(() => import(`./cadastros-secundarios/contratos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-contratos`}
					component={lazy(() => import(`./cadastros-secundarios/lista-contratos`))}
				/>

				{/*Fluxo de Configurações*/}
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-formas-pagamento`}
					component={lazy(() => import(`./configuracoes/lista-formas-pagamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/forma-pagamento/:id`}
					component={lazy(() => import(`./configuracoes/forma-pagamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/forma-pagamento`}
					component={lazy(() => import(`./configuracoes/forma-pagamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-categorias-despesas`}
					component={lazy(() => import(`./configuracoes/lista-categorias-despesas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/categoria-despesa/:id`}
					component={lazy(() => import(`./configuracoes/categoria-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/categoria-despesa`}
					component={lazy(() => import(`./configuracoes/categoria-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-subcategorias-despesas`}
					component={lazy(() => import(`./configuracoes/lista-subcategorias-despesas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/subcategoria-despesa/:id`}
					component={lazy(() => import(`./configuracoes/subcategoria-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/subcategoria-despesa`}
					component={lazy(() => import(`./configuracoes/subcategoria-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-tipos-despesas`}
					component={lazy(() => import(`./configuracoes/lista-tipos-despesas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/tipo-despesa/:id`}
					component={lazy(() => import(`./configuracoes/tipo-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/tipo-despesa`}
					component={lazy(() => import(`./configuracoes/tipo-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-contas-despesas`}
					component={lazy(() => import(`./configuracoes/lista-contas-despesas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/conta-despesa/:id`}
					component={lazy(() => import(`./configuracoes/conta-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/conta-despesa`}
					component={lazy(() => import(`./configuracoes/conta-despesa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-metas-bonificacoes`}
					component={lazy(() => import(`./configuracoes/lista-metas-bonificacoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/meta-bonificacao/:id`}
					component={lazy(() => import(`./configuracoes/meta-bonificacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/meta-bonificacao`}
					component={lazy(() => import(`./configuracoes/meta-bonificacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/outras-configuracoes`}
					component={lazy(() => import(`./configuracoes/outras-configuracoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-permissoes`}
					component={lazy(() => import(`./configuracoes/lista-permissoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/permissao/:id`}
					component={lazy(() => import(`./configuracoes/permissao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/permissao`}
					component={lazy(() => import(`./configuracoes/permissao`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/aulas/dashboard`}
					component={lazy(() => import(`./aulas/dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/aulas/marketing`}
					component={lazy(() => import(`./aulas/marketing`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/whatsapp`}
					component={lazy(() => import(`./configuracoes/whatsapp`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/prontuario`}
					component={lazy(() => import(`./configuracoes/prontuario`))}
				/>

				{/* Relatórios */}
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/orcamentos`}
					component={lazy(() => import(`./relatorios/relatorio-orcamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/media-fechamento`}
					component={lazy(() => import(`./relatorios/media-fechamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/ticket-medio-usuarios`}
					component={lazy(() => import(`./relatorios/ticket-medio-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/ticket-medio-receitas`}
					component={lazy(() => import(`./relatorios/ticket-medio-receitas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/especialidades-feitas`}
					component={lazy(() => import(`./relatorios/especialidades-feitas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/dre-unidade`}
					component={lazy(() => import(`./relatorios/dre-unidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/dre-geral`}
					component={lazy(() => import(`./relatorios/dre-geral`))}
				/>

				<Route path={`${APP_PREFIX_PATH}/whatsapp/:id`} component={lazy(() => import(`./whatsapp/chat`))} />

				<Route
					path={`${APP_PREFIX_PATH}/whatsapp`}
					component={lazy(() => import(`./whatsapp/selecao-numero`))}
				/>
				<Route path={`${APP_PREFIX_PATH}/chat/:id`} component={lazy(() => import(`./chat`))} />
				<Route path={`${APP_PREFIX_PATH}/chat`} component={lazy(() => import(`./chat`))} />

				<Route path={`${APP_PREFIX_PATH}/video-chat`} component={lazy(() => import(`./video-chat`))} />

				{/* Integrações */}
				<Route
					path={`${APP_PREFIX_PATH}/integracoes/asaas`}
					component={lazy(() => import(`./integracoes/asaas`))}
				/>

				{/*treinamentos*/}
				<Route path={`${APP_PREFIX_PATH}/treinamentos/`} component={lazy(() => import(`./treinamentos`))} />

				{/* Consultorio */}
				<Route
					path={`${APP_PREFIX_PATH}/gerenciador-de-senhas/`}
					component={lazy(() => import(`./gerenciador-de-senhas`))}
				/>
				<Route path={`${APP_PREFIX_PATH}/leads/`} component={lazy(() => import(`./leads`))} />

				{/* Páginas de erro */}
				<Route path={`${APP_PREFIX_PATH}/error/404`} component={lazy(() => import(`./error/404`))} />

				<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/agenda`} />
			</Switch>
		</Suspense>
	);
};

const mapStateToProps = ({user}) => {
	return {user: user};
};

export default connect(mapStateToProps)(React.memo(AppViews));
