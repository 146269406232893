import {UPDATE_TREINAMENTO} from '../constants/MenuTreinamento';

const initMenuTreinamento = {
	navigationConfig: [],
};

const menuTreinameto = (state = initMenuTreinamento, action) => {
	switch (action.type) {
		case UPDATE_TREINAMENTO:
			return {
				...state,
				navigationConfig: action.navigationConfig,
			};
		default:
			return state;
	}
};

export default menuTreinameto;
