import { SET_IS_LOADING, SET_TOKEN_EXPIRED, SET_PAYMENT_REQUIRED, SET_PAYMENT_REQUIRED_LOGIN } from 'redux/constants/Gui';

const initGui = {
  isLoading: false,
  isExpired: false,
  paymentRequired: false,
  dadosCobranca: false,
  freeTrial: false,
};

const gui = (state = initGui, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_TOKEN_EXPIRED:
      return {
        ...state,
        isExpired: action.isExpired,
      };
    case SET_PAYMENT_REQUIRED:
      return {
        ...state,
        paymentRequired: action.paymentRequired,
        dadosCobranca: action.dadosCobranca,
        freeTrial: action.freeTrial,
      };
    case SET_PAYMENT_REQUIRED_LOGIN:
      return {
        ...state,
        paymentRequired: action.paymentRequired,
        dadosCobranca: action.dadosCobranca,
        freeTrial: action.freeTrial,
      };

    default:
      return state;
  }
};

export default gui