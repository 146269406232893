import fetch from 'auth/FetchInterceptor'
import store from "../redux/store";
import {HomeOutlined} from '@ant-design/icons';
import {UPDATE_TREINAMENTO} from "../redux/constants/MenuTreinamento";
import {DashboardSVG, StatusGeraisSVG} from "../assets/svg/icon";
import { TREINAMENTO_PREFIX_PATH } from "../configs/AppConfig";
import menuTitleUtil from "../utils/MenuTitleUtil";

const treinamentoService = {}

const elementoMenuStatico = {
        key: 'dashboard',
        path: `${TREINAMENTO_PREFIX_PATH}/dashboard`,
        title: 'Menu',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: []
    };
treinamentoService.populateMenuData = async function () {
    let menuTreinamentoData = await treinamentoService.getMenuTreinamentoData(); 
    
    const menuTreinamentoTeste = await menuTreinamentoData.map(categoria => {
        return {
            showTitle: true,
            title: categoria.nome,
            submenu: categoria.lista_modulos_curso.map(modulo => {
                return {
                    key: `${modulo.id}`,
                    title: modulo.nome,
                    icon: StatusGeraisSVG,
                    breadcrumb: false,
                    submenu: modulo.submodulos_curso.map(submodulo => {
                        return {
                            key: submodulo.nome.toLowerCase(),
                            title: menuTitleUtil.formatarTitulo(submodulo.nome),
                            path: `${TREINAMENTO_PREFIX_PATH}/submodulos/${submodulo.id}`,
                            icon: DashboardSVG,
                            breadcrumb: false,
                            submenu: []
                        }
                    })
                }
            })
        }
    })
    
    menuTreinamentoTeste.unshift(elementoMenuStatico)
    store.dispatch({
        type: UPDATE_TREINAMENTO,
        navigationConfig: menuTreinamentoTeste
    });
}

treinamentoService.getMenuTreinamentoData = async function () {
    return fetch({
        url: '/v1/rest/menu-treinamento',
        method: 'get',
    })
};

treinamentoService.getById = async (id) => {
    return fetch({
        url: `/v1/rest/submodulo-curso/${id}`,
        method: 'get'
    })
};

export default treinamentoService;