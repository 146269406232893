import CryptoJS from 'crypto-js';
const UrlEncryptUtil = {}

UrlEncryptUtil.encrypt =  (secret, value) => {
    return CryptoJS.AES.encrypt(value, secret).toString().replace(/\+/g, 'xMl3Jk').replace(/\//g, 'Por21Ld').replace(/=/g, 'Ml32')
}

UrlEncryptUtil.decrypt = (secret, value) => {
    try{
        const bytes = CryptoJS.AES.decrypt(value.toString().replace(/xMl3Jk/g, '+' ).replace(/Por21Ld/g, '/').replace(/Ml32/g, '='), secret);
        return bytes.toString(CryptoJS.enc.Utf8);
    }catch(e){
        console.error(e);
        return false;
    }
}

export default UrlEncryptUtil;