export const styles = {
    main_col: {
        marginBottom: '40px',
    },
    card: {
        width: '100%',
        height: '100%',
        borderRadius: '15.5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        padding: '30px 20px',
        position: 'relative',
        transition: "transform 0.3s ease-in-out",
    },
    card_hover: {
        width: '100%',
        height: '100%',
        borderRadius: '15.5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        padding: '30px 20px',
        position: 'relative',
        transform: "translateY(-5px)",
        transition: "transform 0.3s ease-in-out",
    },
    icons_row: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    bookmark: {
        position: 'absolute',
        background: '#056D61',
        textAlign: 'center',
        width: 'min-content',
        fontSize: '14px',
        left: '-1px',
        borderRadius: '0 15.5px 15.5px 0',
        padding: '5px 20px',
        color: '#FFF',
    },
    icon_col: {
        textAlign: 'center',
    },
    icon: {
        width: '110px',
        height: '110px',
    }, 
    main_row: {
        justifyContent: 'center',
    },
    currency: {
        fontSize: '25px',
        fontWeight: '600',
        color: '#000',
    },
    price: {
        fontSize: '52px',
        fontWeight: '600',
        color: '#000',
    },
    active_users: {
        fontSize: '16px',
        color: '#435761',
        fontWeight: '500',
    },
    description_row: {
        marginTop: '30px',
        justifyContent: 'center',
    },
    description: {
        fontSize: '16px',
        color: '#454545',
        fontWeight: '400',
    },
    attribute_row: {
        marginTop: 14
    },
    attribute_col: {
        display: "flex"
    },
    attribute: {
        fontSize: '15px',
        color: '#454545',
        fontWeight: '400',
        flex: 1,
    },
    attribute_icon: {
        fontSize: '15px',
        color: '#12E6A8',
    },
    subscribe_button: {
        position: 'absolute',
        height: '47.2px',
        fontSize: '16px',
        padding: '10px 40px 10px 40px',
        fontWeight: '400',
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(170deg, #12E6A8 0%, #056D61 100%)',
        color: '#FFF',
        left: '50%',
        transform: 'translateX(-50%)',
        border: 'none',
        bottom: '-23.6px',
    },
    subscribe_button_hover: {
        position: 'absolute',
        height: '47.2px',
        fontSize: '16px',
        padding: '10px 40px 10px 40px',
        fontWeight: '400',
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(200deg, #056D61 0%, #089176 100%)',
        color: '#FFF',
        left: '50%',
        transform: 'translateX(-50%)',
        border: 'none',
        bottom: '-23.6px',
    }
};
