import fetch from 'auth/FetchInterceptor'

const faturaPlanoService = {}

faturaPlanoService.get = async function(params) {
    const {
        page = 0,
        pageSize = 5,
        search = '',
        orderBy = 'id',
        orderDirection = 'asc',
        filters = {}
    } = params;

    const queryParams = {
        page,
        pageSize,
        search,
        orderBy,
        orderDirection,
        filters
    }
    
    return fetch (
            {
            url: '/v2/clini-pro/rest/fatura-plano-sistema',
            method: 'get',
            params: queryParams
        }
    )
}

faturaPlanoService.getPlano = async function() {    
    return fetch (
            {
            url: '/v2/clini-pro/rest/plano-sistema',
            method: 'get',
        }
    )
}

faturaPlanoService.getDadosCobranca = async function() {
    return fetch (
            {
            url: '/v2/clini-pro/rest/dados-cobranca-sistema',
            method: 'get',
        }
    )
}

faturaPlanoService.postEscolhaPlano = async function(values) {
    return fetch(
            {
            url: '/v2/clini-pro/rest/escolher-plano-sistema' ,
            method: 'post',
            data: values,
        }
    )
}

faturaPlanoService.postDadosCobranca = async function(values) {
    return fetch(
            {
            url: '/v2/clini-pro/rest/dados-cobranca-sistema' ,
            method: 'post',
            data: values,
        }
    )
}

faturaPlanoService.putTrocarPlano = async function(values) {
    return fetch(
            {
            url: '/v2/clini-pro/rest/trocar-plano-sistema' ,
            method: 'put',
            data: values,
        }
    )
}

faturaPlanoService.informePagamento = async function(values) {
    return fetch(
            {
            url: '/v2/clini-pro/rest/informe-pagamento-sistema' ,
            method: 'post',
            data: values,
        }
    )
}

export default faturaPlanoService;