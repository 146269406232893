import { io } from 'socket.io-client';
import { env } from '../configs/EnvironmentConfig'

const chatUrl = env.API_SOCKET_ENDPOINT_URL + 'chat';
const videoUrl = env.API_SOCKET_ENDPOINT_URL + 'video';

export const socket = io(chatUrl, {
    path: env.API_SOCKET_SUFIX_PATH,
})

export const VideoSocket = io(videoUrl, {
    path: env.API_SOCKET_SUFIX_PATH,
})
