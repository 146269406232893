import {MedicineBoxOutlined, CreditCardFilled, FileSearchOutlined} from '@ant-design/icons';
import {
	RelatoriosMenuSVG,
	SubmenuRelatoriosSVG,
	CalendarioMenuSVG,
	PacientesMenuSVG,
	OrcamentoMenuSVG,
	FinaneciroMenuSVG,
	FinaneciroSubmenuSVG,
	EstoqueMenuSVG,
	CadastroMenuSVG,
	ConfiguracoesMenuSVG,
	GerenciadorDeSenhasMenuSVG,
	LeadsMenuSVG,
	AnamneseMenuSVG,
	EmpresasMenuSVG,
	EquipeMenuSVG,
	FornecedoresMenuSVG,
	IndicacoesMenuSVG,
	PlanosMenuSVG,
	RotulosMenuSVG,
	UnidadesMenuSVG,
	TipoDespesasMenuSVG,
	MetasBonificacoesMenuSVG,
	FormasPagamentosMenuSVG,
	PermissoesMenuSVG,
	CategoriasDespesasMenuSVG,
	ContasDespesasMenuSVG,
	RegistrosMenuSVG,
	QuestionarioSVG,
	ProfissionalSVG,
	ContratosMenuSVG,
	ChatMenuSVG,
	IntegracaoMenuSVG,
	AsaasMenuSVG,
} from 'assets/svg/icon';
import {APP_PREFIX_PATH, CAIXA_PREFIX_PATH} from 'configs/AppConfig';
import Permissoes from 'enum/permissao-enum';

const dashBoardNavTree = [
	{
		key: 'agenda',
		path: `${APP_PREFIX_PATH}/agenda`,
		title: 'agenda',
		icon: CalendarioMenuSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.AGENDA.VISUALIZAR,
	},
	{
		key: 'lista-pacientes',
		path: `${APP_PREFIX_PATH}/lista-pacientes`,
		title: 'lista-pacientes',
		icon: PacientesMenuSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.PACIENTES.VISUALIZAR,
	},
	{
		key: 'relacao_orcamentos',
		path: `${APP_PREFIX_PATH}/relacao-de-orcamentos`,
		title: 'relacao_orcamentos',
		icon: OrcamentoMenuSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.ORCAMENTOS.VISUALIZAR,
	},
	{
		submenu: [
			{
				key: 'financeiro',
				title: 'financeiro',
				icon: FinaneciroMenuSVG,
				submenu: [
					{
						key: 'fluxo-de-caixa',
						path: `${APP_PREFIX_PATH}/financeiro/fluxo-de-caixa`,
						title: 'fluxo-de-caixa',
						icon: FinaneciroSubmenuSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.FLUXO_CAIXA.VISUALIZAR,
					},
					{
						key: 'inadimplentes',
						path: `${APP_PREFIX_PATH}/financeiro/inadimplentes`,
						title: 'inadimplentes',
						icon: FinaneciroSubmenuSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.INADIMPLENTES.VISUALIZAR,
					},
					{
						key: 'comissoes',
						path: `${APP_PREFIX_PATH}/financeiro/comissoes`,
						title: 'comissoes',
						icon: FinaneciroSubmenuSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.COMISSOES.VISUALIZAR,
					},
					{
						key: 'metas',
						path: `${APP_PREFIX_PATH}/financeiro/metas`,
						title: 'metas',
						icon: FinaneciroSubmenuSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.METAS.VISUALIZAR,
					},
				],
				permissao: Permissoes.FINANCEIRO.VISUALIZAR,
			},
		],
	},
	{
		submenu: [
			{
				key: 'relatorios',
				title: 'relatorios',
				icon: RelatoriosMenuSVG,
				permissao: Permissoes.RELATORIOS.VISUALIZAR,
				submenu: [
					{
						key: 'relatorios/media-fechamento',
						path: `${APP_PREFIX_PATH}/relatorios/media-fechamento`,
						title: 'relatorios/media-fechamento',
						icon: SubmenuRelatoriosSVG,
						breadcrumb: false,
						permissao: Permissoes.MEDIA_FECHAMENTOS_VENDAS.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'relatorios/ticket-medio-receitas',
						path: `${APP_PREFIX_PATH}/relatorios/ticket-medio-receitas`,
						title: 'relatorios/ticket-medio-receitas',
						icon: SubmenuRelatoriosSVG,
						breadcrumb: false,
						permissao: Permissoes.TICKET_MEDIO_RECEITA.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'relatorios/ticket-medio-usuarios',
						path: `${APP_PREFIX_PATH}/relatorios/ticket-medio-usuarios`,
						title: 'relatorios/ticket-medio-usuarios',
						icon: SubmenuRelatoriosSVG,
						breadcrumb: false,
						permissao: Permissoes.TICKET_MEDIO_USUARIO.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'relatorios/relatorio-orcamentos',
						path: `${APP_PREFIX_PATH}/relatorios/relatorio-orcamentos`,
						title: 'relatorios/relatorio-orcamentos',
						icon: SubmenuRelatoriosSVG,
						breadcrumb: false,
						permissao: Permissoes.RELACAO_APROVADOS_NAO_APROVADOS.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'relatorios/resumo',
						path: `${APP_PREFIX_PATH}/relatorios/resumo`,
						title: 'relatorios/resumo',
						icon: SubmenuRelatoriosSVG,
						breadcrumb: false,
						permissao: Permissoes.RESUMOS.VISUALIZAR,
						submenu: [],
					},
				],
			},
		],
	},
	{
		key: 'documentos',
		path: `${APP_PREFIX_PATH}/documentos`,
		title: 'documentos',
		icon: FileSearchOutlined,
		breadcrumb: false,
		permissao: Permissoes.WIKI.VISUALIZAR,
		submenu: [],
	},
	{
		key: 'estoque',
		path: `${APP_PREFIX_PATH}/lista-estoque`,
		title: 'lista-estoque',
		icon: EstoqueMenuSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.ESTOQUE.VISUALIZAR,
	},
	// {
	// 	key: 'gerenciador-de-senhas',
	// 	path: `${APP_PREFIX_PATH}/gerenciador-de-senhas`,
	// 	title: 'gerenciador-de-senhas',
	// 	icon: GerenciadorDeSenhasMenuSVG,
	// 	breadcrumb: false,
	// 	submenu: [],
	// 	permissao: Permissoes.GERENCIADOR_DE_SENHAS.VISUALIZAR,
	// },
	{
		key: 'chat',
		path: `${APP_PREFIX_PATH}/chat`,
		title: 'chat',
		icon: ChatMenuSVG,
		breadcrumb: false,
		permissao: Permissoes.CHAT.VISUALIZAR_CHAT,
		submenu: [],
	},
	{
		key: 'leads',
		path: `${APP_PREFIX_PATH}/leads`,
		title: 'leads',
		icon: LeadsMenuSVG,
		breadcrumb: false,
		permissao: Permissoes.LEADS.VISUALIZAR,
		submenu: [],
	},
	{
		submenu: [
			{
				key: 'cadastros-secundarios',
				title: 'cadastros-secundarios',
				icon: CadastroMenuSVG,
				permissao: Permissoes.CADASTRO.VISUALIZAR,
				submenu: [
					{
						key: 'cadastros-secundarios/lista-anamnese',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-anamneses`,
						title: 'cadastros-secundarios/lista-anamneses',
						icon: AnamneseMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.ANAMNESE.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-contratos',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-contratos`,
						title: 'cadastros-secundarios/lista-contratos',
						icon: ContratosMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.CONTRATO.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-empresas',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-empresas`,
						title: 'cadastros-secundarios/lista-empresas',
						icon: EmpresasMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.EMPRESA.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-especialidades',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-especialidades`,
						title: 'cadastros-secundarios/lista-especialidades',
						icon: MedicineBoxOutlined,
						breadcrumb: false,
						permissao: Permissoes.ESPECIALIDADE.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-usuarios',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-usuarios`,
						title: 'cadastros-secundarios/lista-usuarios',
						icon: EquipeMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.EQUIPE.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-profissionais',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-profissionais`,
						title: 'cadastros-secundarios/lista-profissionais',
						icon: ProfissionalSVG,
						breadcrumb: false,
						permissao: Permissoes.PROFISSIONAIS.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-fornecedores',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-fornecedores`,
						title: 'cadastros-secundarios/lista-fornecedores',
						icon: FornecedoresMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.FORNECEDORES.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-indicacoes',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-indicacoes`,
						title: 'cadastros-secundarios/lista-indicacoes',
						icon: IndicacoesMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.INDICACOES.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-planos',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-planos`,
						title: 'cadastros-secundarios/lista-planos',
						icon: PlanosMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.PLANOS_SERVICOS.VISUALIZAR_PLANO,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-questionarios',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-questionarios`,
						title: 'cadastros-secundarios/lista-questionarios',
						icon: QuestionarioSVG,
						breadcrumb: false,
						permissao: Permissoes.QUESTIONARIOS.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-rotulos',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-rotulos`,
						title: 'cadastros-secundarios/lista-rotulos',
						icon: RotulosMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.ROTULOS.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-unidades',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-unidades`,
						title: 'cadastros-secundarios/lista-unidades',
						icon: UnidadesMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.UNIDADES.VISUALIZAR,
						submenu: [],
					},
				],
			},
		],
	},
	{
		submenu: [
			{
				key: 'configuracoes',
				title: 'configuracoes',
				icon: ConfiguracoesMenuSVG,
				submenu: [
					{
						key: 'configuracoes/lista-tipos-despesas',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-tipos-despesas`,
						title: 'configuracoes/lista-tipos-despesas',
						icon: TipoDespesasMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.TIPO_DESPESA.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'configuracoes/lista-metas-bonificacoes',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-metas-bonificacoes`,
						title: 'configuracoes/lista-metas-bonificacoes',
						icon: MetasBonificacoesMenuSVG,
						permissao: Permissoes.METAS_BONIFICACOES.VISUALIZAR,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'configuracoes/lista-formas-pagamento',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-formas-pagamento`,
						title: 'configuracoes/lista-formas-pagamento',
						icon: FormasPagamentosMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.FORMAS_PAGAMENTO.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'configuracoes/lista-permissoes',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-permissoes`,
						title: 'configuracoes/lista-permissoes',
						icon: PermissoesMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.PERMISSAO.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'configuracoes/lista-categorias-despesas',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-categorias-despesas`,
						title: 'configuracoes/lista-categorias-despesas',
						icon: CategoriasDespesasMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.CATEGORIA_DESPESA.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'configuracoes/lista-subcategorias-despesas',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-subcategorias-despesas`,
						title: 'configuracoes/lista-subcategorias-despesas',
						icon: CategoriasDespesasMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.SUBCATEGORIA_DESPESA.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'configuracoes/lista-contas-despesas',
						path: `${APP_PREFIX_PATH}/configuracoes/lista-contas-despesas`,
						title: 'configuracoes/lista-contas-despesas',
						icon: ContasDespesasMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.CONTA_DESPESA.VISUALIZAR,
						submenu: [],
					},
					{
						key: 'cadastros-secundarios/lista-registros',
						path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-registros`,
						title: 'cadastros-secundarios/lista-registros',
						icon: RegistrosMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.REGISTROS.VISUALIZAR,
						submenu: [],
					},
				],
				permissao: Permissoes.CONFIGURACOES.VISUALIZAR,
			},
		],
	},
	{
		submenu: [
			{
				key: 'integracoes',
				title: 'integracoes',
				icon: IntegracaoMenuSVG,
				submenu: [
					{
						key: 'integracoes/asaas',
						path: `${APP_PREFIX_PATH}/integracoes/asaas`,
						title: 'integracoes/asaas',
						icon: AsaasMenuSVG,
						breadcrumb: false,
						permissao: Permissoes.ASAAS.VISUALIZAR,
						submenu: [],
					},
				],
				permissao: Permissoes.CONFIGURACOES.VISUALIZAR,
			},
		],
	},
	{
		key: 'faturas-sistema',
		path: `${CAIXA_PREFIX_PATH}/`,
		title: 'faturas-sistema',
		icon: CreditCardFilled,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.PLANOS_DO_SISTEMA.VISUALIZAR,
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
