import fetch from 'auth/FetchInterceptor';
import {AUTH_TOKEN} from 'redux/constants/Auth';

const JwtAuthService = {};

// TODO: após clonar o skeleton, ajustar essa função
JwtAuthService.login = function (usuario, senha_node) {
  const body = {
    usuario: usuario,
    senha_node: senha_node
  };

  return fetch({
    url: '/v1/rest/login',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: body
  });
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: '/v2/rest/novo-usuario',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  });
};

JwtAuthService.isLogged = () => {
  return localStorage.getItem(AUTH_TOKEN) !== null;
};

export default JwtAuthService;
