const menuTitleUtil = {}

menuTitleUtil.formatarTitulo = (titulo) => {

    if(!titulo) return null;

    if(titulo.length >= 21) {
        return formatarTituloGrande(titulo);
    }else{
        return titulo
    }

}

function formatarTituloGrande(titulo) {
    return titulo.substring(0,18) + '...';
}


export default menuTitleUtil;