import { PUBLIC_PREFIX_PATH } from 'configs/AppConfig';
import React from 'react'
import { Switch, Route, } from "react-router-dom";
import DevicesViews from 'views/devices-views'

const moment = require('moment')

export const DevicesLayout = () => {
	const getLogo = () => {
		return `${PUBLIC_PREFIX_PATH}/cliniProLogo.png`;
	};

	return (
		<div className="device-container">
			<header
				style={{
					backgroundColor: '#004C4D',
					height: '50px',
					width: '100%',
				}}
			>
				<div style={{
					margin: "auto", 
					width: "min-content",
					paddingRight: "20px",
				}}>
					<img
						src={getLogo()}
						alt={`logo`}
						style={{
							marginTop: "5px",
							height: "40px",
						}}
					/>
				</div>
			</header>
				<Switch>
					<Route path="" component={DevicesViews} />
				</Switch>
			<footer
				style={{
					position: 'fixed',
					backgroundColor: '#004C4D',
					height: '50px',
					width: '100%',
					bottom: 0,
				}}
			>					
				<h5
				style={{
					color: '#fff',
					textAlign: 'center',
					fontWeight: 'lighter',
					margin: "5px"
				}}
			>{moment().format("DD/MM/YYYY HH:mm")}</h5></footer>
		</div>
	)
}


export default DevicesLayout
