import React from 'react';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
// import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import {setTokenExpired} from 'redux/actions/Gui';
import LoginModal from 'components/organism/LoginModal';
import {Layout, Grid} from 'antd';

import navigationConfig from 'configs/NavigationConfig';
import {
	SIDE_NAV_WIDTH,
	SIDE_NAV_COLLAPSED_WIDTH,
	NAV_TYPE_SIDE,
	NAV_TYPE_TOP,
	DIR_RTL,
	DIR_LTR,
} from 'constants/ThemeConstant';
import utils from 'utils';
import {useThemeSwitcher} from 'react-css-theme-switcher';
import EscolherPlanoModal from 'components/organism/EscolherPlanoModal';

const {Content} = Layout;
const {useBreakpoint} = Grid;

export const AppLayout = ({navCollapsed, navType, location, direction, isExpired, paymentRequired, freeTrial}) => {
	const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes('lg');
	const isNavSide = navType === NAV_TYPE_SIDE;
	const isNavTop = navType === NAV_TYPE_TOP;
	const getLayoutGutter = () => {
		if (isNavTop || isMobile) {
			return 0;
		}
		return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
	};

	const {status} = useThemeSwitcher();

	if (status === 'loading') {
		return <Loading cover="page" />;
	}

	const getLayoutDirectionGutter = () => {
		if (direction === DIR_LTR) {
			return {paddingLeft: getLayoutGutter()};
		}
		if (direction === DIR_RTL) {
			return {paddingRight: getLayoutGutter()};
		}
		return {paddingLeft: getLayoutGutter()};
	};

	return (
		<Layout>
			<HeaderNav isMobile={isMobile} />
			{isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo}  navigationConfig={navigationConfig} /> : null}
			<Layout className="app-container">
				{isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} navigationConfig={navigationConfig} /> : null}
				<Layout className="app-layout" style={getLayoutDirectionGutter()}>
					<div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
						<PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
						<Content>
							<AppViews />
							<Modal
								destroyOnClose={true}
								visible={isExpired}
								maskClosable={false}
								width={400}
								footer={null}
								closable={false}
								style={{top: 40}}>
								<LoginModal />
							</Modal>
							<Modal
								destroyOnClose={true}
								visible={paymentRequired && freeTrial}
								maskClosable={false}
								width={1400}
								footer={null}
								bodyStyle={{background: 'none'}}
								closable={false}
							>
								<EscolherPlanoModal />
							</Modal>
						</Content>
					</div>
					{/* <Footer /> */}
				</Layout>
			</Layout>
			{isMobile && <MobileNav navigationConfig={navigationConfig} />}
		</Layout>
	);
};

const mapStateToProps = ({theme, gui}) => {
	const {navCollapsed, navType, locale} = theme;
	const {isExpired, paymentRequired, freeTrial} = gui;
	return {navCollapsed, navType, locale, isExpired, paymentRequired, freeTrial};
};

const mapDispatchToProps = {setTokenExpired};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppLayout));
