import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav';
import PageHeader from 'components/layout-components/PageHeader';
import {Layout, Grid} from 'antd';
import {
	SIDE_NAV_WIDTH,
	SIDE_NAV_COLLAPSED_WIDTH,
	NAV_TYPE_SIDE,
	NAV_TYPE_TOP,
	DIR_RTL,
	DIR_LTR,
} from 'constants/ThemeConstant';
import utils from 'utils';
import {useThemeSwitcher} from 'react-css-theme-switcher';
import TreinamentoViews from '../../views/treinamento-views';
import JwtAuthService from '../../services/JwtAuthService';
import treinamentoService from '../../services/TreinamentoService';
import HeaderNav from 'components/layout-components/HeaderNav';
import Footer from 'components/layout-components/Footer';
import { PUBLIC_PREFIX_PATH } from "configs/AppConfig"

const {Content} = Layout;
const {useBreakpoint} = Grid;

export const TreinamentoLayout = ({navCollapsed, navType, location, direction, navigationConfigTreinamento}) => {
	const currentRouteInfo = utils.getRouteInfo(navigationConfigTreinamento, location.pathname);
	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes('lg');
	const isNavSide = navType === NAV_TYPE_SIDE;
	const isNavTop = navType === NAV_TYPE_TOP;
	const getLayoutGutter = () => {
		if (isNavTop || isMobile) {
			return 0;
		}

		if (isMobile) {
			return SIDE_NAV_COLLAPSED_WIDTH;
		}

		return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
	};

	const {status} = useThemeSwitcher();

	const populateMenuTreinamento = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await treinamentoService.populateMenuData();
			}
		} catch (e) {}
	};

	useEffect(() => {
		populateMenuTreinamento();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if (status === 'loading') {
		return <Loading cover="page" />;
	}

	const getLayoutDirectionGutter = () => {
		if (direction === DIR_LTR) {
			return {paddingLeft: getLayoutGutter()};
		}
		if (direction === DIR_RTL) {
			return {paddingRight: getLayoutGutter()};
		}
		return {paddingLeft: getLayoutGutter()};
	};

	
	return (
		<Layout>
			<div
				style={{
					backgroundImage: `url(${PUBLIC_PREFIX_PATH}/bannerOralBrasil.jpg)`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}>
				{<HeaderNav isMobile={isMobile} />}
				{(isNavTop && !isMobile) ? (
					<TopNav navigationConfig={navigationConfigTreinamento} routeInfo={currentRouteInfo} />
				) : null}
				<Layout className="app-container">
					{(isNavSide && !isMobile) ? (
						<SideNav
							navigationConfig={navigationConfigTreinamento}
							routeInfo={currentRouteInfo}
						/>
					) : null}
					<Layout className="app-layout" style={getLayoutDirectionGutter()}>
						<div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
							<PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
							<Content>
								<TreinamentoViews />
							</Content>
						</div>
						<Footer />
					</Layout>
				</Layout>
				{isMobile && <MobileNav navigationConfig={navigationConfigTreinamento} />}
			</div>
		</Layout>
	);
};

const mapStateToProps = ({theme, menuTreinamento}) => {
	const {navCollapsed, navType, locale} = theme;

	return {navCollapsed, navType, locale, navigationConfigTreinamento: menuTreinamento.navigationConfig};
};

export default connect(mapStateToProps)(React.memo(TreinamentoLayout));
