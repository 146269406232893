import { CAIXA_PREFIX_PATH, SITE_URL } from 'configs/AppConfig';
import { all, takeEvery, fork } from 'redux-saga/effects';
import { SET_PAYMENT_REQUIRED } from 'redux/constants/Gui';

export function* paymentRequired() {
    yield takeEvery(SET_PAYMENT_REQUIRED, function* (payload) {
        const { paymentRequired, freeTrial, dadosCobranca } = payload;
        if(paymentRequired && !freeTrial) {
            if(dadosCobranca)
                window.open(`${SITE_URL}/#${CAIXA_PREFIX_PATH}/faturas`, '_self');
            else
                window.open(`${SITE_URL}/#${CAIXA_PREFIX_PATH}/dados-cobranca`, '_self');
        }else{
            yield null;
        }
    });
}

export default function* rootSaga() {
  yield all([
		fork(paymentRequired),
  ]);
}
