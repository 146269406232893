import { Button, Card, Col, Row } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { styles } from "./styles"
import { useEffect, useState } from "react"
import StandardIcon from "./icone_standard.svg"
import PremiumIcon from "./icone_premium.svg"
import faturaPlanoService from "services/FaturaPlanoService"
import { useHistory } from "react-router-dom"
import { CAIXA_PREFIX_PATH } from "configs/AppConfig"
import { setPaymentRequired } from "redux/actions/Gui"
import { connect } from "react-redux"

const EscolherPlanoModal = (props) => {
    const {
        setPaymentRequired,
        freeTrial,
        isLoadingPlano,
        id_plano,
        dadosCobranca,
        isModal = true
    } = props

    const [button1hover, setButton1Hover] = useState(false)
    const [button2hover, setButton2Hover] = useState(false)
    const [card1hover, setCard1Hover] = useState(false)
    const [card2hover, setCard2Hover] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory();

    const setModalBackground = () => {
        document.getElementsByClassName('ant-modal-content')[0].style.background = 'transparent'
        document.getElementsByClassName('ant-modal-content')[0].style.boxShadow = 'none'
    }

    const escolherPlano = async (plano) => {
        setIsLoading(true)
        try{
            if(freeTrial){
                await faturaPlanoService.postEscolhaPlano({plano: plano})
                setPaymentRequired(!dadosCobranca, false, dadosCobranca)
            }else{
                await faturaPlanoService.putTrocarPlano({plano: plano})
            }
            setIsLoading(false)
            history.push(`${CAIXA_PREFIX_PATH}/`)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        setIsLoading(isLoadingPlano)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingPlano])

    useEffect(() => {
        if(isModal) setModalBackground()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<Row gutter={40}>
        <Col xs={24} md={12}  
            style={styles.main_col}
        >
            <Card
                style={card1hover ? styles.card_hover : styles.card}
                onMouseEnter={() => setCard1Hover(true)}
                onMouseLeave={() => setCard1Hover(false)}
            >
                <Row 
                    style={styles.icons_row}
                >
                    <div
                        style={styles.bookmark}
                    >
                        PREMIUM
                    </div>
                    <Col
                        style={styles.icon_col}
                    >
                        <img 
                            src={PremiumIcon}
                            alt={StandardIcon}
                            style={styles.icon}
                        />  
                    </Col>
                </Row>
                <Row
                    style={styles.main_row}
                >
                    <Col>
                        <label
                            style={styles.currency}
                        >
                            R$
                        </label>
                        <label
                            style={styles.price}
                        >
                            119,90
                        </label>
                    </Col>
                </Row>
                <Row
                    style={styles.main_row}
                >
                    <Col>
                        <label
                            style={styles.active_users}
                        >
                            para 5 usuários simultâneos
                        </label>
                    </Col>
                </Row>
                <Row
                    style={styles.description_row}
                >
                    <Col>
                        <label
                            style={styles.description}
                        >
                            Para você que busca controle total da sua gestão, tenha recursos exclusivos para aproveitar o sistema ao máximo!
                        </label>
                    </Col>
                </Row>
                <hr/>
                <Row style={styles.attribute_row}>
                    <Col xs={24} md={24} style={styles.attribute_col}>
                        <label
                            style={styles.attribute}
                        >
                            Dashboard Multiclínicas
                        </label>
                        <CheckOutlined 
                            style={styles.attribute_icon}
                        />
                    </Col>
                </Row>
                <Row style={styles.attribute_row}>
                    <Col xs={24} md={24} style={styles.attribute_col}>
                        <label
                            style={styles.attribute}
                        >
                            Metas
                        </label>
                        <CheckOutlined 
                            style={styles.attribute_icon}
                        />
                    </Col>
                </Row>
                <Row style={styles.attribute_row}>
                    <Col xs={24} md={24} style={styles.attribute_col}>
                        <label
                            style={styles.attribute}
                        >
                            NFS-e**
                        </label>
                        <CheckOutlined 
                            style={styles.attribute_icon}
                        />
                    </Col>
                </Row>
                {id_plano === 2
                    ? <Button
                        style={styles.subscribe_button_hover}
                        loading={isLoading}
                        disabled={true}
                    >
                        ASSINADO
                    </Button>
                    : <Button
                        style={button1hover ? styles.subscribe_button_hover :  styles.subscribe_button}
                        onMouseEnter={() => setButton1Hover(true)}
                        onMouseLeave={() => setButton1Hover(false)}
                        onClick={() => escolherPlano(2)}
                        loading={isLoading}
                    >
                        ASSINE AGORA
                    </Button>
                }
            </Card>
        </Col>
        <Col xs={24} md={12} 
            style={styles.main_col}
        >
            <Card
                style={card2hover ? styles.card_hover : styles.card}
                onMouseEnter={() => setCard2Hover(true)}
                onMouseLeave={() => setCard2Hover(false)}
            >
                <Row 
                    style={styles.icons_row}
                >
                    <div
                        style={styles.bookmark}
                    >
                        STANDARD
                    </div>
                    <Col
                        style={styles.icon_col}
                    >
                        <img 
                            src={StandardIcon}
                            alt={PremiumIcon}
                            style={styles.icon}
                        />  
                    </Col>
                </Row>
                <Row
                    style={styles.main_row}
                >
                    <Col>
                        <label
                            style={styles.currency}
                        >
                            R$
                        </label>
                        <label
                            style={styles.price}
                        >
                            69,90
                        </label>
                    </Col>
                </Row>
                <Row
                    style={styles.main_row}
                >
                    <Col>
                        <label
                            style={styles.active_users}
                        >
                            para 2 usuários simultâneos
                        </label>
                    </Col>
                </Row>
                <Row
                    style={styles.description_row}
                >
                    <Col>
                        <label
                            style={styles.description}
                        >
                            Para você que busca recursos básicos para a rotina da sua clínica, conte com maior praticidade na sua gestão!
                        </label>
                    </Col>
                </Row>
                <hr/>
                <Row style={styles.attribute_row}>
                    <Col xs={24} md={24} style={styles.attribute_col}>
                        <label
                            style={styles.attribute}
                        >
                            Dashboard Multiclínicas
                        </label>
                        <CloseOutlined 
                            style={styles.attribute_icon}
                        />
                    </Col>
                </Row>
                <Row style={styles.attribute_row}>
                    <Col xs={24} md={24} style={styles.attribute_col}>
                        <label
                            style={styles.attribute}
                        >
                            Metas
                        </label>
                        <CloseOutlined 
                            style={styles.attribute_icon}
                        />
                    </Col>
                </Row>
                <Row style={styles.attribute_row}>
                    <Col xs={24} md={24} style={styles.attribute_col}>
                        <label
                            style={styles.attribute}
                        >
                            NFS-e**
                        </label>
                        <CloseOutlined 
                            style={styles.attribute_icon}
                        />
                    </Col>
                </Row>
                {id_plano === 1
                    ? <Button
                        style={styles.subscribe_button_hover}
                        loading={isLoading}
                        disabled={true}
                    >
                        ASSINADO
                    </Button>
                    : <Button
                        style={button2hover ? styles.subscribe_button_hover :  styles.subscribe_button}
                        onMouseEnter={() => setButton2Hover(true)}
                        onMouseLeave={() => setButton2Hover(false)}
                        onClick={() => escolherPlano(1)}
                        loading={isLoading}
                    >
                        ASSINE AGORA
                    </Button>
                }
            </Card>
        </Col>
    </Row>)
}

const mapStateToProps = ({gui}) => {
	const {freeTrial, dadosCobranca} = gui;
	return {freeTrial, dadosCobranca};
};

const mapDispatchToProps = {
	setPaymentRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EscolherPlanoModal);