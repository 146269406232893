import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig';

export const APP_NAME = 'DIMO';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const TREINAMENTO_PREFIX_PATH = '/treinamento';
export const DEVICES_PREFIX_PATH = '/devices';
export const CAIXA_PREFIX_PATH = '/caixa';
export const PUBLIC_PREFIX_PATH = env.PUBLIC_PREFIX_PATH;
export const SITE_URL = env.SITE_URL;
export const SOCKET_ENDPOINT_URL = env.SOCKET_ENDPOINT_URL;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'pt',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#004C4D',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
};
