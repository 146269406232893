export const styles = {
    main_container: {
        position: 'absolute',
        width: '100%',
        top: '70px',
        boxSizing: 'border-box',
    },
    main_card: {
        backgroundColor: '#3AB39E4D', 
        color: '#fff',
        margin: 'auto',
        marginBottom: '10px',
        width: '280px',
    },
    password_title: {
        fontStyle: "normal",
        fontSize: "40px",
        lineHeight: "40px",
        color: '#004C4D',
    },
    password: {
        fontStyle: "normal",
        fontSize: "36px",
        lineHeight: "36px",
        color: '#004C4D',
    },
    qr_code: {
        width: "150px",
        height: "150px",
        padding: "10px",
        margin: "auto",
        borderRadius: "10%",
        backgroundColor: "#fff",
    }
}