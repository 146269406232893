const dev = {
  API_ENDPOINT_URL: 'http://localhost:8087/clinipro/api',
  SOCKET_ENDPOINT_URL: 'http://localhost:8080',
  SITE_URL: 'http://localhost:3000',
  SITE_SUFIX_PATH: '',
  PUBLIC_PREFIX_PATH: '',
  API_SOCKET_ENDPOINT_URL: 'http://localhost:8087/',
  API_SOCKET_SUFIX_PATH: '/socket.io'
};

const prod = {
  API_ENDPOINT_URL: 'https://api.clinipro.com.br/clinipro-backend2/clinipro/api',
  SOCKET_ENDPOINT_URL: 'https://api.clinipro.com.br/clinipro-backend2',
  SITE_URL: 'https://app.clinipro.com.br',
  PUBLIC_PREFIX_PATH: '',
  API_SOCKET_ENDPOINT_URL: 'https://api.clinipro.com.br/',
  API_SOCKET_SUFIX_PATH: '/clinipro-backend2/clinipro/socket.io'
};

const test = {
  API_ENDPOINT_URL: 'https://homolog.dimo.com.br/clinipro/api',
  SOCKET_ENDPOINT_URL: 'https://homolog.dimo.com.br',
  PUBLIC_PREFIX_PATH: '/clini',
  SITE_URL: 'https://homolog.dimo.com.br',
  SITE_SUFIX_PATH: '/clini',
  API_SOCKET_ENDPOINT_URL: 'https://homolog.dimo.com.br/',
  API_SOCKET_SUFIX_PATH: '/clinipro/socket.io'
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
