const Permissoes = {};

Permissoes.AGENDA = {
	VISUALIZAR: 1,
	CRIAR: 2,
	EDITAR: 3,
	ESCOLHER_PROFISSIONAL: 4,
};

Permissoes.PACIENTES = {
	VISUALIZAR: 5,
	CRIAR: 6,
	EDITAR: 7,
	SOBRE: 8,
	ORCAMENTO_VISUALIZAR: 9,
	ORCAMENTO_CRIAR: 10,
	ORCAMENTO_EDITAR: 11,
	ORCAMENTO_EXCLUIR: 12,
	ORCAMENTO_APROVAR: 13,
	ORCAMENTO_REVERTER: 14,
	SERVICO_VISUALIZAR: 15,
	ANAMNESE_VISUALIZAR: 16,
	ANAMNESE_SALVAR: 17,
	IMAGENS_VISUALIZAR: 18,
	IMAGENS_ADICIONAR: 19,
	IMAGENS_EXCLUIR: 20,
	DOCUMENTOS_VISUALIZAR: 21,
	DOCUMENTOS_ADICIONAR: 22,
	DOCUMENTOS_EXCLUIR: 23,
	DEBITOS_VISUALIZAR: 24,
	DEBITOS_RECEBER: 25,
	DEBITOS_REVERTER: 26,
	DEBITOS_EXCLUIR: 27,
	DEBITOS_REPARCELAR: 28,
	DEBITOS_GERAR_BOLETO: 29,
	UTILIZAR_CARTAO_CREDITO_ASAAS: 150,
	DEBITOS_GERAR_PIX: 151,
};

Permissoes.ORCAMENTOS = {
	VISUALIZAR: 30,
};

Permissoes.FINANCEIRO = {
	VISUALIZAR: 31,
};

Permissoes.FLUXO_CAIXA = {
	VISUALIZAR: 32,
	CRIAR_DESPESA: 33,
	EDITAR_DESPESA: 34,
	EXCLUIR_DESPESA: 35,
	VISUALIZAR_DESPESA: 36,
	VISUALIZAR_RECEITA: 37,
	RECEBER_RECEITA: 38,
};

Permissoes.INADIMPLENTES = {
	VISUALIZAR: 39,
};

Permissoes.COMISSOES = {
	VISUALIZAR: 40,
	PAGAR: 41,
	EXCLUIR: 42,
};

Permissoes.METAS = {
	VISUALIZAR: 43,
	PAGAR: 44,
};

Permissoes.RELATORIOS = {
	VISUALIZAR: 45,
};

Permissoes.MEDIA_FECHAMENTOS_VENDAS = {
	VISUALIZAR: 46,
};

Permissoes.TICKET_MEDIO_RECEITA = {
	VISUALIZAR: 47,
};

Permissoes.TICKET_MEDIO_USUARIO = {
	VISUALIZAR: 48,
};

Permissoes.RELACAO_APROVADOS_NAO_APROVADOS = {
	VISUALIZAR: 49,
};

Permissoes.RESUMOS = {
	VISUALIZAR: 50,
};

Permissoes.ESTOQUE = {
	VISUALIZAR: 51,
	CRIAR: 52,
	EDITAR: 53,
	EXCLUIR: 54,
	RETIRAR: 55,
};

Permissoes.GERENCIADOR_DE_SENHAS = {
	VISUALIZAR: 56,
	CRIAR_PACIENTE: 57,
	CHAMAR_PROXIMA_SENHA: 58,
};

Permissoes.LEADS = {
	VISUALIZAR: 59,
	CRIAR: 60,
	EDITAR: 61,
};

Permissoes.CADASTRO = {
	VISUALIZAR: 62,
};

Permissoes.ANAMNESE = {
	VISUALIZAR: 63,
	CRIAR: 64,
	EDITAR: 65,
	DESATIVAR: 66,
};

Permissoes.CONTRATO = {
	VISUALIZAR: 67,
	CRIAR: 68,
	EDITAR: 69,
};

Permissoes.EMPRESA = {
	VISUALIZAR: 70,
	CRIAR: 71,
	EDITAR: 72,
	DESATIVAR: 73,
};

Permissoes.ESPECIALIDADE = {
	VISUALIZAR: 74,
	CRIAR: 75,
	EDITAR: 76,
	DESATIVAR: 77,
};

Permissoes.EQUIPE = {
	VISUALIZAR: 78,
	CRIAR: 79,
	EDITAR: 80,
	DESATIVAR: 81,
	ATRIBUIR_UNIDADES: 82,
};

Permissoes.PROFISSIONAIS = {
	VISUALIZAR: 83,
	CRIAR: 84,
	EDITAR: 85,
	DESATIVAR: 86,
};

Permissoes.FORNECEDORES = {
	VISUALIZAR: 87,
	CRIAR: 88,
	EDITAR: 89,
	DESATIVAR: 90,
};

Permissoes.INDICACOES = {
	VISUALIZAR: 91,
	CRIAR: 92,
	EDITAR: 93,
	DESATIVAR: 94,
};

Permissoes.PLANOS_SERVICOS = {
	VISUALIZAR_PLANO: 95,
	CRIAR_PLANO: 96,
	EDITAR_PLANO: 97,
	DESATIVAR_PLANO: 98,
	VISUALIZAR_SERVICO: 99,
	CRIAR_SERVICO: 100,
	EDITAR_SERVICO: 101,
	DESATIVAR_SERVICO: 102,
};

Permissoes.QUESTIONARIOS = {
	VISUALIZAR: 103,
	CRIAR: 104,
	EDITAR: 105,
	DESATIVAR: 106,
};

Permissoes.ROTULOS = {
	VISUALIZAR: 107,
	CRIAR: 108,
	EDITAR: 109,
	DESATIVAR: 110,
};

Permissoes.UNIDADES = {
	VISUALIZAR: 111,
	CRIAR: 112,
	EDITAR: 113,
	DESATIVAR: 114,
};

Permissoes.CONFIGURACOES = {
	VISUALIZAR: 115,
};

Permissoes.TIPO_DESPESA = {
	VISUALIZAR: 116,
	CRIAR: 117,
	EDITAR: 118,
	DESATIVAR: 119,
};

Permissoes.METAS_BONIFICACOES = {
	VISUALIZAR: 120,
	CRIAR: 121,
	EDITAR: 122,
	DESATIVAR: 123,
};

Permissoes.FORMAS_PAGAMENTO = {
	VISUALIZAR: 124,
	CRIAR: 125,
	EDITAR: 126,
	DESATIVAR: 127,
};

Permissoes.PERMISSAO = {
	VISUALIZAR: 128,
	CRIAR: 129,
	EDITAR: 130,
	DESATIVAR: 131,
};

Permissoes.CATEGORIA_DESPESA = {
	VISUALIZAR: 132,
	CRIAR: 133,
	EDITAR: 134,
	DESATIVAR: 135,
};

Permissoes.SUBCATEGORIA_DESPESA = {
	VISUALIZAR: 136,
	CRIAR: 137,
	EDITAR: 138,
	DESATIVAR: 139,
};

Permissoes.CONTA_DESPESA = {
	VISUALIZAR: 140,
	CRIAR: 141,
	EDITAR: 142,
	DESATIVAR: 143,
};

Permissoes.REGISTROS = {
	VISUALIZAR: 144,
};

Permissoes.INTEGRACOES = {
	VISUALIZAR: 152,
};

Permissoes.ASAAS = {
	VISUALIZAR: 145,
	VISUALIZAR_UNIDADE: 146,
	ATIVAR_WEBHOOK_COBRANCA: 147,
	VISUALIZAR_TRANSFERENCIA_BANCARIA: 148,
	EFETUAR_TRANSFERENCIA_BANCARIA: 149,
};

Permissoes.PLANOS_DO_SISTEMA = {
	VISUALIZAR: 153,
	INFORMAR_PAGAMENTO: 154,
	ESCOLHER_PLANO: 155,
	ALTERAR_DADOS_COBRANCA: 156,
};

Permissoes.WIKI = {
	VISUALIZAR: 157,
	ADICIONAR: 158,
	DOWNLOAD: 159,
	EXCLUIR: 160,
};

Permissoes.ALIMENTOS = {
	VISUALIZAR: 161,
	CRIAR: 162,
	EDITAR: 163,
	EXCLUIR: 164,
};

Permissoes.EXERCICIOS_FISICOS = {
	VISUALIZAR: 165,
	CRIAR: 166,
	EDITAR: 167,
	EXCLUIR: 168,
	CRIAR_INTENSIDADE: 169,
};

Permissoes.EMAGREFLIX = {
	VISUALIZAR: 170,
	ADICIONAR: 171,
	EXCLUIR: 172,
};

Permissoes.CHAT = {
	VISUALIZAR_CHAT: 173,
	VISUALIZAR_CHAT_PACIENTES: 174,
};

Permissoes.PRONTUARIO = {
	CRIAR: 175,
	EDITAR_CONFIG: 176,
};

module.exports = Permissoes;
