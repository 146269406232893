import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import history from '../history';
import {AUTH_TOKEN} from 'redux/constants/Auth';
import {notification} from 'antd';
import store from 'redux/store';
import {ID_UNIDADE} from 'redux/constants/User';
import {SET_TOKEN_EXPIRED, SET_PAYMENT_REQUIRED} from 'redux/constants/Gui';
import {UPDATE_PERMISSOES} from 'redux/constants/Permissoes';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

// Config
const ENTRY_ROUTE = '#/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const UNIDADE_ID = 'id_unidade';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
	config => {
		const jwtToken = config.token ?? localStorage.getItem(AUTH_TOKEN);
		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
		}

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		const unidade = localStorage.getItem(ID_UNIDADE);

		if (unidade) {
			config.headers[UNIDADE_ID] = unidade;
		} else {
			config.headers[UNIDADE_ID] = undefined;
		}

		return config;
	},
	error => {
		// Do something with request error here
		notification.error({
			message: 'Error',
		});
		Promise.reject(error);
	},
);

// API respone interceptor
service.interceptors.response.use(
	response => {
		return response.data;
	},
	error => {
		const store_gui = store.getState().gui;

		if (!store_gui.isExpired && !store_gui.paymentRequired) {
			let notificationParam = {
				message: '',
			};

			if (!error.response) {
				notificationParam.message = 'Erro inesperado';
				notificationParam.description = error.message;
				localStorage.removeItem(AUTH_TOKEN);
				notification.error(notificationParam);
				return Promise.reject(error);
			}

			// Remove token and redirect
			if (error.response.status === 400) {
				notificationParam.message = 'Authentication Fail';
				notificationParam.description = 'Please login again';
				localStorage.removeItem(AUTH_TOKEN);
				history.push(ENTRY_ROUTE);
				window.location.reload();
			}

			if (error.response.status === 401) {
				notificationParam.message = 'Authentication Fail';
				localStorage.removeItem(AUTH_TOKEN);
				store.dispatch({
					type: SET_TOKEN_EXPIRED,
					isExpired: true,
				});
				history.push(ENTRY_ROUTE);
				window.location.reload();
			}

			if (error.response.status === 402) {
				notificationParam.message = error.response.data.message ?? 'Pagamento Pendente';
				if (error.response.data.free_trial) {
					store.dispatch({
						type: SET_PAYMENT_REQUIRED,
						paymentRequired: true,
						freeTrial: true,
						dadosCobranca: error.response.data.dados_cobranca,
					});
				} else {
					store.dispatch({
						type: SET_PAYMENT_REQUIRED,
						paymentRequired: true,
						freeTrial: false,
						dadosCobranca: error.response.data.dados_cobranca,
					});
				}

				store.dispatch({
					type: UPDATE_PERMISSOES,
					permissoes: error.response.data.permissoes,
				});
			}

			if (error.response.status === 403) {
				notificationParam.message = error.response.data.message ?? 'Recurso não encontrado';
				if (!error.response.data.qtd_usuarios && !error.response.data.qtd_usuarios) {
					history.push('#/app/error/404');
					window.location.reload();
				}
			}

			if (error.response.status === 404) {
				history.push('#/app/error/404');
				window.location.reload();
			}

			if (error.response.status === 500) {
				notificationParam.message = error.response.data.message ?? 'Internal Server Error';
				if (error.response.data.message === 'Failed to authenticate token.') {
					localStorage.removeItem(AUTH_TOKEN);
					history.push(ENTRY_ROUTE);
					window.location.reload();
				}
			}

			if (error.response.status === 508) {
				notificationParam.message = 'Time Out';
			}

			if (error.response.status !== 401 && error.response.status !== 402) {
				notification.error(notificationParam);
			}
		} else {
			store.dispatch({
				type: UPDATE_PERMISSOES,
				permissoes: error.response.data.permissoes,
			});
		}

		return Promise.reject(error);
	},
);

export default service;
