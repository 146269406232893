import React, {useEffect, useState} from 'react';
import {Row, Col, Tag, Progress, notification} from 'antd';
import {ClockCircleOutlined} from '@ant-design/icons';
import {COLORS} from 'constants/ChartConstant';
import Flex from 'components/shared-components/Flex';
import metaService from 'services/MetaService';
import {setIsLoading} from 'redux/actions/Gui';

const ItemHeader = ({name, category}) => (
	<div>
		<h4 className="mb-0">{name}</h4>
		<span className="text-muted">{category}</span>
	</div>
);

const ItemInfo = ({completedTask, totalTask, statusColor, dayleft}) => (
	<Flex alignItems="center">
		<div className="mr-2">
			<span className="ml-2 text-muted">
				{completedTask}/{totalTask}
			</span>
		</div>
		<div>
			<Tag
				className={statusColor === 'none' ? 'bg-gray-lightest' : ''}
				color={statusColor !== 'none' ? statusColor : ''}>
				<ClockCircleOutlined />
				<span className="ml-2 font-weight-semibold">Faltam {dayleft} dia(s)</span>
			</Tag>
		</div>
	</Flex>
);

const ItemProgress = ({progression}) => (
	<Progress percent={progression} strokeColor={getProgressStatusColor(progression)} size="small" />
);

const ListItem = ({data, removeId}) => (
	<div className="bg-white rounded p-3 mb-3 border">
		<Row align="middle">
			<Col xs={24} sm={24} md={9}>
				<ItemHeader name={data.name} category={data.category} />
			</Col>
			<Col xs={24} sm={24} md={9}>
				<ItemInfo
					completedTask={data.completedTask}
					totalTask={data.totalTask}
					statusColor={data.statusColor}
					dayleft={data.dayleft}
				/>
			</Col>
			<Col xs={24} sm={24} md={5}>
				<ItemProgress progression={data.progression} />
			</Col>
		</Row>
	</div>
);

const getProgressStatusColor = progress => {
	if (progress >= 80) {
		return COLORS[1];
	}
	if (progress < 60 && progress > 30) {
		return COLORS[3];
	}
	if (progress < 30) {
		return COLORS[2];
	}
	return COLORS[0];
};

const MetasUsuario = props => {
	const [list, setList] = useState([]);
	const obterDados = async () => {
		try {
			setIsLoading(true);
			await Promise.all([setList(await metaService.getAllByUser())]);
			setIsLoading(false);
		} catch (e) {
			notification.error({
				message: 'Erro',
				description: 'Erro ao obter dados',
			});
		}
		setIsLoading(false);
	};

	useEffect(() => {
		obterDados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div>
				<h2>Minhas metas</h2>
			</div>
			<div className={`my-4 container`}>
				{list.map(elm => (
					<ListItem data={elm} key={elm.id} />
				))}
			</div>
		</>
	);
};

export default MetasUsuario;
