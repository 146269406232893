import fetch from 'auth/FetchInterceptor';

const metaService = {};

metaService.getAll = async function (values) {
	return fetch({
		url: '/v1/rest/meta',
		method: 'get',
		params: values,
	});
};
metaService.getAllByUser = async function () {
	return fetch({
		url: '/v1/rest/meta-usuario',
		method: 'get',
	});
};
metaService.pagarMeta = async function (values, id) {
	return fetch({
		url: '/v1/rest/pagar-meta/' + id,
		method: 'post',
		data: values,
	});
};

export default metaService;
