import React, {useState, useEffect} from 'react';
import {Menu, Avatar, Dropdown} from 'antd';
import {connect} from 'react-redux';
import Icon from 'components/util-components/Icon';
import {signOut} from 'redux/actions/Auth';
import {UserOutlined, LogoutOutlined, StockOutlined} from '@ant-design/icons';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Swal from 'sweetalert2';
import {setIsLoading} from '../../redux/actions/Gui';
import {APP_PREFIX_PATH} from 'configs/AppConfig';
import {Link} from 'react-router-dom';
import menuService from '../../services/MenuService';
import dropDownService from 'services/DropDownUnidades';
import {HomeOutlined} from '@ant-design/icons';
import ModalController from 'components/organism/ModalController';
import MetasUsuario from './MetasUsuario';

const menuItem = [];

export const NavProfile = ({signOut, user, navCollapsed}) => {
	const [opcoes, setOpcoes] = useState([]);
	const obterDados = async () => {
		try {
			setIsLoading(true);
			await Promise.all([getOpcoes()]);
			setIsLoading(false);
		} catch (e) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Falha ao buscar os itens recarregue a página!',
			});
		}
		setIsLoading(false);
	};

	const getOpcoes = async () => {
		setOpcoes(await dropDownService.getAll());
	};

	useEffect(() => {
		obterDados();
		return () => {
			setOpcoes([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClick = async ({key}) => {
		await dropDownService.put(key);
		await menuService.populateUnidade();
		await window.location.reload();
	};

	const menu = (
		<Menu onClick={onClick}>
			{opcoes?.map(opcao => (
				<Menu.Item key={opcao.unidade.idunidade}>
					{opcao.unidade.nome}

					<Link to={`${APP_PREFIX_PATH}/agenda`}></Link>
				</Menu.Item>
			))}
		</Menu>
	);

	const profileMenu = (
		<div>
			<div className="nav-profile-header">
				<div
					style={{
						marginBottom: 10,
						height: '40px',
						width: navCollapsed ? '40px' : '230px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
					}}>
					<Avatar
						size="medium"
						style={{
							marginRight: navCollapsed ? 0 : 10,
						}}
						icon={<UserOutlined />}
					/>
					{!navCollapsed && (
						<>
							<h4
								style={{
									position: 'relative',
									top: '50%',
									flex: 1,
									fontSize: 15,
									margin: 0,
									height: 'min-content',
									transform: 'translateY(-50%)',
									color: '#004C4D',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}>
								{user.name} <br />
								<span
									style={{
										color: '#6d7979',
										display: 'flex',
										alignItems: 'center',
									}}>
									<HomeOutlined 
										style={{marginRight: 5}}
									/> ﾠ{user.unidade.nome}
								</span>
							</h4>

							<EllipsisDropdown
								placement="topRight"
								style={{position: 'relative', right: 0}}
								menu={
									<Menu>
										<Menu.Item key="0" onClick={e => signOut()}>
											<div style={{display: 'flex'}}>
												<div style={{flexGrow: 1}}>
													<span>Sair</span>
												</div>
												<div>
													<LogoutOutlined />
												</div>
											</div>
										</Menu.Item>
										<Menu.Item key="1">
											<Dropdown overlay={menu} trigger={['click']}>
												<div style={{display: 'flex', cursor: 'pointer'}}>
													<div style={{flexGrow: 1, flexBasis: 0, textAlign: 'right'}}>
														<span style={{marginRight: 10}}>Alterar unidade</span>
													</div>
													<div>
														<span>
															<HomeOutlined />
														</span>
													</div>
												</div>
											</Dropdown>
										</Menu.Item>
										<Menu.Item key="2">
											<ModalController
												renderButton={params => (
													<div {...params} style={{display: 'flex'}}>
														<div style={{flexGrow: 1}}>
															<span>Minhas metas</span>
														</div>
														<div>
															<StockOutlined />
														</div>
													</div>
												)}
												renderForm={params => <MetasUsuario {...params} />}
												width={1100}
											/>
										</Menu.Item>
									</Menu>
								}
							/>
						</>
					)}
				</div>
			</div>

			<div className="nav-profile-body">
				<Menu>
					{menuItem.map((el, i) => {
						return (
							<Menu.Item key={i}>
								<a href={el.path}>
									<Icon className="mr-3" type={el.icon} />
									<span className="font-weight-normal">{el.title}</span>
								</a>
							</Menu.Item>
						);
					})}
				</Menu>
			</div>
		</div>
	);
	return profileMenu;
};

const mapStateToProps = ({user, theme}) => {
	const {navCollapsed, sideNavTheme} = theme;
	return {user: {...user}, navCollapsed, sideNavTheme};
};

export default connect(mapStateToProps, {signOut})(NavProfile);
